// import { contractAddress } from './contractdetails/contractdetails'

const config = {
  title: 'Cult Bears',
  description:
    'Cult Bears is a culture shift of 2,000 diverse bears, generating passive income on MoonBeam.',
  contractAddress: "0x2a314f5611ba26d947b346537aeb685f911fc26a",
  maxMintAmount: 300,
  presaleMaxMintAmount: 1,
  price: 1
}

export { config }
