import Head from 'next/head'
import Link from 'next/link'
import DropdownContent from '../components/DropdownContent'
import { config } from '../dapp.config'
import ImgCard from '../components/ImgCard'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { Description, SupportedNetworks, PoweredBy } from '../components/UI/supported-networks'
import { CustomDescription } from '../components/UI/description'

export default function Home() {
  const FAQ = [
    {
      question: `What is Layer Zero?`,
      answer: `LayerZero is an omnichain interoperability protocol. Messages, tokens and NFTs can transferred across any chain.`,
      link: 'https://layerzero.network/',
      linkText: 'Learn more'
    },
    {
      question: `What wallet should I use?`,
      answer: `We recommend using Metamask Wallet. You can download it`,
      link: 'https://metamask.io/',
      linkText: 'here'
    },
    {
      question: `What is the supply of the project?`,
      answer: `The total supply is 2,000 Cult Bears, equally split on Moonbeam and Astar. And there are 5 different rarities: common, rare, ultra rare, legendary and mythic!`,
      link: '',
      linkText: ''
    },
    {
      question: `What is the INO V2 price?`,
      answer: `The mint price depends on the NFT rarity and is the same on Moonbeam and Astar.`,
      link: '',
      linkText: ''
    },
    {
      question: `When will the CULT BEARS INO V2 take place?`,
      answer: `The INO V2 is live now:`,
      linkText: 'cultbearsdao.com/mint',
      link: 'Participate'
    },
    {
      question: `Why "Bears"?`,
      answer: `Bears are beatiful and very powerful animals, that's why we decided to go with bears.`,
      link: '',
      linkText: ''
    }
  ]
  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '1.4rem'
  }
  return (
    <div className="min-h-screen h-full w-full flex flex-col bg-brand-light overflow-hidden">
      <Head>
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <link rel="icon" href="/favicon.ico" />
      </Head>

    <header className="min-w-full text-gray-800 py-0 px-0 md:px-0">
        <Navbar collapseOnSelect expand="lg" className="green">
          <Container>
            <Navbar.Brand>
              <img
                src="/images/preview.png"
                className="w-32 h-16 rounded-md object-cover"
              />
              </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto d-flex gap-5 align-items-center">
                <Link href="/">
                  <div style={linkStyle}>Home</div>
                </Link>
                <Link href="/#faq">
                  <div style={linkStyle}>FAQ</div>
                </Link>
                   <Link href="https://x-honey-bridge.vercel.app/">
                  <div style={linkStyle}>X Honey Bridge</div>
                </Link>
                </Nav>
                <Nav className="align-items-center">
                <Nav.Link
                  href="https://moonbeans.io/collections/cultbearsglmr"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <img
                    src="/images/moonbeans.png"
                    className="w-6 h-6 md:w-8 md:h-8"
                  />
                </Nav.Link>

                <Nav.Link
                  href="https://tofunft.com/collection/cultbears-moonbeam/items"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <img
                    src="https://docs.tofunft.com/images/logos/bordered1.svg"
                    className="w-6 h-6 md:w-8 md:h-8"
                  />
                </Nav.Link>                

                <Nav.Link
                  href="https://twitter.com/cultbearsdao"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <svg
                    className="w-6 h-6 md:w-8 md:h-8"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                  </svg>
                </Nav.Link>

                <Nav.Link
                  href="https://t.me/cultbearsdao"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <img src="images/tg.png" className="w-6 h-6 md:w-8 md:h-8" />
                </Nav.Link>

                <Nav.Link
                  href="https://discord.gg/HjGGfMR7Ux"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <svg
                    className="w-6 h-6 md:w-8 md:h-8"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z"></path>
                  </svg>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <div className="wrapper py-8">
        <div className=" items-center justify-center text-center font-coiny text-gray-800 px-4 md:px-0 py-0 mt-0">
          <h1 className="font-bold text-2xl md:text-4xl uppercase">
            Welcome to Cult Bears
          </h1>
                      
            <p className="mt-4 w-full md:w-1/2 max-w-5xl mx-auto text-xl">
            {`Cult Bears DAO is the first cross-chain NFT community eager to explore all chains. Our community-driven DAO envisions to bring continuous innovation to the web3 space.`}
            </p>
            <p className="mt-2 w-full md:w-1/2 text-xl max-w-5xl mx-auto">
            {` Our movement consists of 2,000 bears with 5 different rarities. Each Cult Bear will earn $XHNY by staking their ONFT from their preferred chain.`}
            </p>
            <p className="mt-2 mb-4 max-w-5xl mx-auto text-xl">
            {`XHONEY / $XHNY is the tastiest commuity coin across all chains 🍯⛓️`}
            </p>

          <div className='mt-3 h-full w-full md:container md:ml-5 flex flex-col items-center pt-0'>
          <a 
          className="group flex content-center w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/7 2xl:w-1/8 gap-2 justify-center border-slate-700 border-2 py-2 rounded-md no-underline hover:border-green-700 bg-slate-100 hover:bg-green-700"
          href="https://www.cultbearsdao.com/mint" 
          target="_blank"
          rel="noreferrer"
          >
          <img
          src="/images/rocket2.png" 
          className=''
          alt="Docs" 
          height={25} 
          width={25}
          />
          <a className="text-black text-xl font-bold no-underline">
          Buy NFT</a>
          </a>
          </div>
            
          <div className='mt-3 h-full w-full md:container md:ml-5 flex flex-col items-center pt-0'>
          <a 
          className="group flex content-center w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/7 2xl:w-1/8 gap-2 justify-center border-slate-700 border-2 py-2 rounded-md no-underline hover:border-green-700 bg-slate-100 hover:bg-green-700"
          href="#ecosystem"
          >
          <img
          src="/images/globe.png" 
          className=''
          alt="Docs" 
          height={25} 
          width={25}
          />
          <a className="text-black text-xl font-bold no-underline xl:hidden">
          Ecosystem</a>
          <a className="text-black text-xl font-bold no-underline hidden xl:block ">
          Explore Ecosystem
          </a>
          </a>
          </div>
            
          <div className='mt-3 h-full w-full md:container md:ml-5 flex flex-col items-center pt-0'>
          <a 
          className="group flex content-center w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/7 2xl:w-1/8 gap-2 justify-center border-slate-700 border-2  py-2 rounded-md no-underline hover:border-green-700 bg-slate-100 hover:bg-green-700"
          href="https://cult-bears-dao.gitbook.io/cult-bears-dao/" 
          target="_blank"
          rel="noreferrer"
          >
          <img
          src="/images/docs.svg" 
          className=''
          alt="Docs" 
          height={25} 
          width={25}
          />
          <a className="text-black text-xl font-bold no-underline xl:hidden">
          Docs</a>
          <a className="text-black text-xl font-bold no-underline hidden xl:block ">
          Read Docs
          </a>
          </a>
          </div>
        </div>
       </div>

       <div className="h-full w-full container max-w-5xl mx-auto flex flex-col items-center pt-0 bg-main">
        <div className="flex flex-col items-center max-w-4xl w-full bg-main">
          <h2 className="font-extrabold uppercase ">Meet our Bear Society</h2>
          {/* className="justify-center flex flex-col md:flex-row md:space-x-4 space-y-2 items-center w-full" */}
          <div className="d-flex gap-4 flex-col md:flex-row pt-5">
            <ImgCard
              className="md:-mt-20 md:mb-20"
              img={'/images/bear-images/cult-bear-1072.png'}
              title={'"Cool Punk"'}
              text={
                'The Punk Clan represents some of the earliest adopters of blockchain technology and is an essential part of our Bear Society.'
              }
            />

            <ImgCard
              className="md:mt-5 md:-mb-5"
              img={'/images/bear-images/cult-bear-8.png'}
              title={'"The Captain"'}
              text={
                'With him on board, your fleet will never lose direction and you can explore the unknown to find incredible treasures.'
              }
            />

            <ImgCard
              className="md:-mt-6"
              img={'/images/bear-images/cult-bear-1.png'}
              title={'"Mythic Devil Bear"'}
              text={
                'Mythic Devil Bears are only found on extremely rare occusions, based on legend there are only 4 within the entire bear kingdom. These apex predators are at the top of the food chain and take advantage of other bears abilities'
              }
            />

            <ImgCard
              className="md:mt-5 md:-mb-5"
              img={'/images/bear-images/cult-bear-1077.png'}
              title={'"Cult Hero"'}
              text={
                'There are reports of bears with supernatural abilities also known as Cult Heros, but very little has been discovered about them.'
              }
            />

            <ImgCard
              className="md:-mt-20 md:mb-20"
              img={'/images/bear-images/cult-bear-30.png'}
              title={'"The Beauty"'}
              text={
                'This beauty is worth every mating battle, and even the most powerful rulers find it difficult to resist her.'
              }
            />
          </div>

          <Description />
          <SupportedNetworks />
          <PoweredBy />

          <CustomDescription h2="Our Cross-chain Ecosystem" p="Currently under Development" id="ecosystem"/>
                
          <div className="flex flex-col md:flex-row md:space-x-16 space-y-5 items-center mt-10 w-full">
            {/* Cult Bears Image */}
            <img
              src="/images/cult bears logo.png"
              className="w-64 h-64 rounded-md object-cover"
            />
            <div className="flex flex-col md:items-start items-center justify-center text-center font-coiny text-gray-800 px-4 md:px-0 py-10 mt-14">
              <h2 className="font-bold text-2xl md:text-4xl uppercase">
                Cult Bears DAO
              </h2>
              <p className="mt-6 text-lg text-start">
                {`By utilizing the ONFT721 standard from Layer Zero, Cult Bears can travel across any chain in the future. Owning a Cult Bear gives you direct ownership to our DAO and entitles you for future DAO airdrops and $XHNY staking rewards.`}
              </p>
            </div>
          </div>

            <div className="flex flex-col md:flex-row md:space-x-16 space-y-5 items-center mt-10 w-full">
            {/* XHONEY Image */}
             <img
             src="/images/xhoney.png"
             className="w-64 h-64 rounded-md object-cover md:hidden"
             />
            <div className="flex flex-col md:items-start items-center justify-center text-center font-coiny text-gray-800 px-4 md:pr-4 py-10 m-0">
              <h2 className="font-bold text-2xl md:text-4xl uppercase">
                X Honey Bridge
              </h2>
               <a 
                href="https://x-honey-bridge.vercel.app/"
                target="_blank"
                rel="noreferrer"
                className="no-underline"
               >
               <p 
               className="text-center text-blue-600 font-extrabold">
               Currently live on Testnet
               </p>
              </a>
               <p className="mt-3 text-lg text-start">
                {`Seamlessly transfer $XHNY across 8 different blockchains using our native bridge. Supported networks include Polygon, Avalanche, Moonbeam, Astar, Base, Scroll, Linea and Astar zkEVM.`}
              </p>
            </div>
             <img
             src="/images/xhoney.png"
             className="w-64 h-64 rounded-md object-cover hidden md:block"
             />
          </div>
                
          <div className="flex flex-col md:flex-row md:space-x-16 space-y-5 items-center mt-10 w-full">
            {/* Cult Markets Image */}
            <img
              src="/images/cult markets.png"
              className="w-64 h-64 rounded-md object-cover"
            />

            <div className="flex flex-col md:items-start items-center justify-center text-center font-coiny text-gray-800 px-4 md:px-0 py-10 mt-14">
              <h2 className="font-bold text-2xl md:text-4xl uppercase">
                Cult Markets
              </h2>

              <p className="mt-6 text-lg text-start">
                {`Cult Markets is our Revenue Sharing ONFT Marketplace & Launchpad with support for 8 different blockain networks, governed and curated by the Cult Bears community. We charge a trading fee of 2.5% on all trades, which is shared between Cult Bear NFT holders, our DAO Fund and the team.`}
              </p>
            </div>
          </div>

            <div className="flex flex-col md:flex-row md:space-x-16 space-y-5 items-center mt-10 w-full">
            {/* Cult Verse Image 2 */}
             <img
             src="/images/logo-2.png"
             className="w-64 h-64 rounded-md object-cover md:hidden"
             />
            <div className="flex flex-col md:items-start items-center justify-center text-center font-coiny text-gray-800 px-4 md:pr-4 py-10 m-0">
              <h2 className="font-bold text-2xl md:text-4xl uppercase">
                Cult Verse
              </h2>
               <p className="mt-6 text-lg text-start">
                {`Cult Verse is our upcoming P2E NFT Card game, where players can challenge each other, conquer the forest and earn precious resources. All our ONFT's will be represented as characters with their individual traits and pets. And your pets will have a special ability which gives you an advantage over other players.`}
              </p>
            </div>
             <img
             src="/images/logo-2.png"
             className="w-64 h-64 rounded-md object-cover hidden md:block"
             />
          </div>
          <br />
          <br />
          <br />
          <br />
          <div id="faq" className="green2">
            <h2 className="font-bold text-2xl md:text-4xl uppercase">FAQ</h2>
            <br />
            {FAQ.map((item, index) => {
              return (
                <div key={index} className="py-2">
                  <DropdownContent
                    link={item.link}
                    linkText={item.linkText}
                    head={item.question}
                    body={item.answer}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
