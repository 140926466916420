
import Card from 'react-bootstrap/Card';

function ImgCard(props) {
  return (
    <Card className={props.className} style={{ width: '16rem', borderWidth: '1px', borderColor: 'black' }}>
      <Card.Img variant="top" src={props.img} />
      <Card.Body >
        <Card.Title>
          <div style={{
            textAlign: 'center',
            fontWeight: '600'
          }}>{props.title}</div>
        </Card.Title>
        <Card.Text>
          <span style={{
            fontSize: '16px'
          }}>{props.text}
          </span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ImgCard;