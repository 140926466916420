export const CustomDescription = ({
  h2,
  p,
  id
}) => {
    return (
      <div id="ecosystem">
      <h2 className="text-center text-4xl font-extrabold mb-4 mt-10 md:mt-20">
        {h2}
      </h2>
      <p className="text-center text-blue-600 font-extrabold">
        {p}
      </p>
      </div>
    )
  }
  
