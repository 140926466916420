import Accordion from 'react-bootstrap/Accordion'

function DropdownContent(props) {
  return (
    <Accordion defaultActiveKey="1">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="accordHead">{props.head}</div>
        </Accordion.Header>
        <Accordion.Body>
          <div
            className="accordBody"
            style={{
              textAlign: 'left'
            }}
          >
            {props.body}
            <br />
            <a
              href={props.link}
              style={{
                textDecoration: '',
                color: 'black'
              }}
            >
              {props.linkText}
            </a>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default DropdownContent
