
export const Description = () => {
  return (
    <div>
    <h2 className="text-center mb-4 mt-10 md:mt-20">
      Seamlessly transfer Cult Bears ONFT&apos;s and XHONEY across 8 different networks
    </h2>
    <p className="text-center text-blue-600 font-extrabold">
      Coming Soon!
    </p>
    </div>
  )
}

export const SupportedNetworks = () => {
  return (
    <div className="xl:ml-20 justify-center rounded-xl bg-white border-[1px] border-black mx-4">
    <div className='px-16 mx-4 px-2 grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 pt-6'>
    <img 
    src="/images/polygon.webp" 
    className=''
    alt="Polygon" height={100} width={200} />
    <img 
    src="/images/Avalanche.svg" 
    className=''
    alt="Avalanche" height={100} width={200} />
    <img 
    src="/images/moonbeam.png" 
    className=''
    alt="Moonbeam" height={100} width={200} />
    <img 
    src="/images/astar2.svg" 
    className=''
    alt="Astar" height={100} width={200} />
    <img 
    src="/images/base3.png" 
    className=''
    alt="Base" height={100} width={200} />
    <img 
    src="/images/scroll.png" 
    className=''
    alt="Scroll" height={100} width={200} />
    <img 
    src="/images/linea.png" 
    className=''
    alt="Linea" height={100} width={200} />
    <img 
    src="/images/astarzkevm.png" 
    className='-mt-2'
    alt="Astar zkEVM" height={100} width={200} />
    </div>
    </div>
  )
}

export const PoweredBy = () => {
  return (
    <div className="grid justify-center gap-2 mb-3 md:ml-10 w-1/2">
    <p className="font-bold mt-4">powered by</p>
    <div className="grid justify-center gap-4">
    <img
    src="/images/layerzero.png" 
    className=''
    alt="Layer Zero" height={150} width={300} />
    <img
    src="/images/polyhedra.svg" 
    className='bg-black p-2'
    alt="Polyhedra" height={150} width={300} />
    </div>
    </div>
  )
}

